<template>
<div>
    <div v-if="debug">
        <v-btn v-if="match" @click="deleteEventCertificate">Delete This Certificate</v-btn>
    </div>

    <template v-if="match">
        <div v-html="match.record.html"/>

        <center>
            <!-- <v-btn class="teal" dark link :href="match.link.url" target="_blank"> -->
            <v-btn class="teal" @click="downloadCertificate" dark link>
                <str index="post_event.buttons.download_certificate._text"/>
            </v-btn>
        </center>

        <div style="text-align: right;">
            <v-btn class="teal button_continue" dark @click="$emit('continue')">
                <str index="buttons.continue._text"/>
            </v-btn>
        </div>
    </template>

    <template v-else>
        <img :src="images.Waves_TL" id="Waves_TL" v-show="false"/>
        <img :src="images.Waves_BR" id="Waves_BR" v-show="false"/>
        <div id='pdf_html'>
            <div class="certificate" style="position: relative; border: 1px solid lightgrey; border-radius: 5px; border-collapse: collapse; background-size: 35%; background-repeat: no-repeat;" :style="{'background-image':`URL(${base64.Waves_TL})`}">
                <div style="display:none;" v-html="css"/>

                <div style="width: 100%; text-align: center; margin: 50px 0px 30px 0px;">
                    <img :src="images.logo.en" style="display: inline-block; width: 400px; max-width: 80%"/>
                    <h2><str index="certificate.subtitle._text"/></h2>
                </div>

                <table class="activity" style="width: 90%; margin: 0 auto; border-spacing: 0px; border: 1px solid lightgrey;">
                    <thead style="background-color: #1AA294; height: 50px; color: white;">
                        <tr>
                            <th>
                                <str index="certificate.table.presentation_title._text"/>
                            </th>
                            <th>
                                <str index="certificate.table.hours._text"/>
                            </th>
                            <th>
                                <str index="certificate.table.activity._text"/>
                            </th>
                            <th>
                                <str index="certificate.table.presenter._text"/>
                            </th>
                        </tr>
                    </thead>
                    <tbody style="background-color: white;">
                        <tr>
                            <td>
                                {{eventData.title[[eventData.language]]}}
                            </td>

                            <td>
                                {{eventDuration}}
                            </td>

                            <td>
                                <str index="certificate.activity._text"/>
                            </td>

                            <td>
                                {{speaker.given_name}} {{speaker.family_name}} <span style="font-weight: normal; font-size: 9pt;">{{speaker.credentials[eventData.language]}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style="margin-top: 50px; min-height: 300px; background-size: 35%; background-repeat: no-repeat; background-position: bottom right;" :style="{'background-image':`URL(${base64.Waves_BR})`}">
                    <div class="description">
                        <div class="col">
                            <div style="background-color: #00A0BE; color: white; font-weight: bold; padding: 15px;">
                                <str index="certificate.table.course_description._text"/>
                            </div>
                            <h3 style="color: black;"><str index="certificate.table.course_description_header._text"/></h3>
                            <div style="background-color: white;" v-html="eventData.learningObjectives[eventData.language]"/>
                        </div>

                        <div class="col" style="text-align: center;">
                            <div style="text-align: left; background-color: white; width: 80%; border: 1px solid grey; border-radius: 3px; margin: 0 auto; padding: 25px;">
                                <b><str index="common.date._text"/></b>: {{formatDate(eventData.start_time)}}<br>
                                <b><str index="common.name._text"/></b>: {{$store.getters.user.attributes.given_name}} {{$store.getters.user.attributes.family_name}}
                                <!-- <br> -->
                                <!-- <b><str index="certificate.license._text"/></b>: -->
                            </div>
                            <img :src="images.Logo_Black" style="width: 80%; display: inline-block; margin: 35px; "/>
                        </div>
                    </div>
                </div>

            </div>

            <div class="certificate-questions" v-if="ui.saving">
                <div v-for="question in questions" :key="`question_${question.id}`">
                    <b><str :index="question.label"/></b>
                    <div v-html="form.certificate.questions[question.id]" style="margin-bottom: 25px; border-bottom: 1px solid lightgrey; min-height: 80px;"/>
                </div>
            </div>
        </div>

        <v-form ref="certificate-questions" v-model="validForm">
            <template v-if="!ui.saving">
                <template v-for="question in questions">
                    <v-textarea v-model="form.certificate.questions[question.id]" :key="`question_${question.id}`" required :rules="rules.required">
                        <template v-slot:label>
                            <str :index="question.label"/>
                        </template>
                    </v-textarea>
                </template>
            </template>
        </v-form>

        <div style="margin: 50px; color: grey; font-size: 10pt; text-align: center;">
            <str index="certificate.footer._text"/>
        </div>

        <center>
            <v-btn :disabled="ui.busy || !validForm" @click="save('pdf_html')" color="teal" :dark="!ui.busy && validForm">
                <v-icon v-if="ui.busy">mdi-spin mdi-loading</v-icon>
                <str v-else index="buttons.submit._text"/>
            </v-btn>
        </center>
    </template>

    <!-- <hr>

    <v-btn @click="getLink">getLink</v-btn>
    <v-btn @click="getRecords">getRecords</v-btn>
    <template v-if="certificateLink">
        <h4>TTL: {{certificateLink.ttl}}</h4>
        <a :href="certificateLink.url" target="_blank">Download</a>
    </template> -->
</div>
</template>

<script>
import validations from '@/plugins/validations.js'
import Logo_EN from './assets/logo.png'
import Logo_Black from './assets/logo_black.png'
import Waves_BR from './assets/waves_br.png'
import Waves_TL from './assets/waves_tl.png'
import html2pdf from "html2pdf.js";

export default {
    props: {
        event_id: {
            type: Number,
            required: true
        },
        eventData: {
            type: Object,
            required: true
        },
        speaker: {
            type: Object,
            required: true
        },
        Hasher: {
            type: Object,
            required: true
        }
    },
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        this.init()
    },
    data: function(){
        return {
            validForm: false,
            validations: null,
            rules: null,
            base64: {
                Waves_TL: null,
                Waves_BR: null
            },
            ui: {
                busy: false,
                saving: false
            },
            records: [],
            config: {
                approval_number: '-----'
            },
            form: {
                certificate: {
                    html: null,
                    questions: {
                        1: null,
                        2: null,
                        3: null
                    }
                }
            },
            questions: [
                {
                    id: 1,
                    label: 'certificate.question_1._text'
                },
                {
                    id: 2,
                    label: 'certificate.question_2._text'
                },
                {
                    id: 3,
                    label: 'certificate.question_3._text'
                }
            ]
        }
    },
    methods: {
        init: async function(){
            await this.getRecords()
            this.$emit('complete', this.match!=null)
            if(!this.match){
                this.makeBase64('pdf_html')
                this.base64Background()
            }
        },
        downloadCertificate: async function(){
            // CREATE: dateString
            let date        = new Date(Date.now());
            let yearNumber  = date.getFullYear();
            let monthNumber = date.getMonth()+1;
            if( monthNumber.toString().length === 1){
                monthNumber = `0${monthNumber}`;
            };
            let dayNumber   = date.getDate();
            if( dayNumber.toString().length === 1){
                dayNumber   = `0${dayNumber}`;
            };
            let dateString  = `${yearNumber}-${monthNumber}-${dayNumber}`;
            // GET:
            let certificateAndSurveyHTML = document.querySelector('.certificate').parentNode;
            // EVENT:
            html2pdf(certificateAndSurveyHTML,{
                filename: `certificate_${dateString}.pdf`
            });
        },
        getRecords: async function(){
            let response = await this.sendRequest('get',`/api/certificate/list`)
            this.records = response.data
        },
        formatDate: function(timestamp){
            let map = {
                month: {
                    1: {
                        "en-ca": 'Jan',
                        "fr-ca": 'Janv'
                    },
                    2: {
                        "en-ca": 'Feb',
                        "fr-ca": 'Févr'
                    },
                    3: {
                        "en-ca": 'Mar',
                        "fr-ca": 'Mars'
                    },
                    4: {
                        "en-ca": 'Apr',
                        "fr-ca": 'Avril'
                    },
                    5: {
                        "en-ca": 'May',
                        "fr-ca": 'Mai'
                    },
                    6: {
                        "en-ca": 'Jun',
                        "fr-ca": 'Juin'
                    },
                    7: {
                        "en-ca": 'Jul',
                        "fr-ca": 'Juil'
                    },
                    8: {
                        "en-ca": 'Aug',
                        "fr-ca": 'Août'
                    },
                    9: {
                        "en-ca": 'Sep',
                        "fr-ca": 'Sept'
                    },
                    10: {
                        "en-ca": 'Oct',
                        "fr-ca": 'Oct'
                    },
                    11: {
                        "en-ca": 'Nov',
                        "fr-ca": 'Nov'
                    },
                    12: {
                        "en-ca": 'Dec',
                        "fr-ca": 'Déc'
                    }
                },
                day: {
                    1: {
                        "en-ca": 'Mon',
                        "fr-ca": 'Lun'
                    },
                    2: {
                        "en-ca": 'Tue',
                        "fr-ca": 'Mar'
                    },
                    3: {
                        "en-ca": 'Wed',
                        "fr-ca": 'Mer'
                    },
                    4: {
                        "en-ca": 'Thur',
                        "fr-ca": 'Jeu'
                    },
                    5: {
                        "en-ca": 'Fri',
                        "fr-ca": 'Ven'
                    },
                    6: {
                        "en-ca": 'Sat',
                        "fr-ca": 'Sam'
                    },
                    7: {
                        "en-ca": 'Sun',
                        "fr-ca": 'Dim'
                    },
                }
            }
            let t = new Date(timestamp)
            let day = map.day[t.getDay()][this.eventData.language]
            let month = map.month[t.getMonth()+1][this.eventData.language]
            let date = t.getDate()
            let year = t.getFullYear()

            return `${day}, ${month} ${date} ${year}`
        },
        makeBase64: async function(target_id){
            let imageElems = document.querySelectorAll("#"+target_id+" img")
            for(let i=0; i<imageElems.length; i++){
                let elem = imageElems[i]
                let target = elem.getAttribute('src')
                let relink = target.search("data:image/png;base64")<0

                if(relink){
                    fetch(elem.src)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                elem.setAttribute('src',reader.result)
                            };
                            reader.readAsDataURL(blob);
                        });
                }
            }
        },
        base64Background: function(){
            let self = this
            let Waves_TL = document.querySelector('#Waves_TL')
            fetch(Waves_TL.src)
                .then((res) => res.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        self.base64.Waves_TL = reader.result
                        // elem.setAttribute('src',reader.result)
                    };
                    reader.readAsDataURL(blob);
                });
            let Waves_BR = document.querySelector('#Waves_BR')
            fetch(Waves_BR.src)
                .then((res) => res.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        self.base64.Waves_BR = reader.result
                        // elem.setAttribute('src',reader.result)
                    };
                    reader.readAsDataURL(blob);
                });
        },
        save: async function(target_id){
            let self = this
            function prep(){
                return new Promise((resolve)=>{
                    self.ui.busy = true
                    self.ui.saving = true

                    setTimeout(function(){
                        resolve()
                    },1000)
                })
            }
            await prep()
            this.form.certificate.html = document.getElementById(target_id).innerHTML
            let data = {html: this.form.certificate.html, event_id: this.event_id, answers: this.form.certificate.questions}
            let response = await this.sendRequest('post',`/api/certificate/create`, data)
            await this.getRecords()
            this.ui.busy = false
            this.ui.saving = false
        },
        deleteEventCertificate: async function(){
            let event_id = this.event_id
            let record_id = this.match.record.id
            let response = await this.sendRequest('delete',`/api/certificate/delete`,{event_id, record_id})
            this.$emit('resetStep')
            await this.init()

        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        images: function(){
            return {
                logo: {
                    en: Logo_EN
                },
                Logo_Black,
                Waves_TL,
                Waves_BR,
            }
        },
        date: function(){
            let today = new Date();
            return today.toDateString();
        },
        match: function(){
            let match = null
            let items = this.records
            for(let i=0; i<items.length; i++){
                let item = items[i]
                if(item.record.event_id==this.event_id){
                    match = item
                    this.form.certificate.questions = item.record.answers
                }
            }
            return match
        },
        css: function(){
            return `<style type='text/css'>
                    .certificate, .certificate-questions{
                        font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
                    }
                    .certificate table.activity tbody td{
                        text-align: center;
                        font-weight: bold;
                    }
                    .description{
                        margin: 50px 5% 25px;
                        display: flex;
                        flex-direction: row;
                        align-content: flex-start
                        justify-content: flex-start;
                    }
                    .description .col{
                    }
                    .description .col:first-child{
                        flex: 2 1 0;
                    }
                    .description .col:nth-child(2){
                        flex: 1 1 0;
                    }

                    .certificate-questions{
                        margin-top: 100px;
                        padding: 25px;
                        break-inside: avoid;
                    }

                    @media only screen and (max-width: 600px){
                        .description{
                            flex-direction: column;
                        }
                    }

                    </style>`
                    // page.addStyleTag(
                    //     {"content": "@page {size: auto}"}
                    // )
        },
        language: function(){
            return this.$store.getters.language
        },
        eventDuration: function(){
            return +(Math.round(((this.eventData.end_time - this.eventData.start_time)/1000/3600) + "e+2")  + "e-2")
        }
    },
    watch: {
        match: function(){
            if(this.match!=null){
                console.log('Certificate match found, emitting complete')
                this.$emit('complete', true)
            }
        }
    }
}
</script>

<style lang='scss'>

    .certificate {

        // Certificate scrollable for when table contents can't compress anymore
        overflow-x: scroll;

        // spacing rules
        .description {
            margin: 50px 5% 25px;

            > .col {
                padding: 12px 0;
            }

            > .col:nth-of-type(1) {

                > div,
                > h3 {
                    margin-bottom: 8px;
                }

                > div:nth-of-type(2),
                > h3 {
                    margin-left: 4px;
                }
            }
        }
    }

    .button_continue {
        @media(max-width: 600px){
            display: block !important;
            margin: 16px auto 0 !important;
        }
    }
</style>
